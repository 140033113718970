<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">standing orders</div>
      </v-row>
      <v-row class="">
        <div class="">
          Permanent rules which govern the conduct of business in a House of
          Assembly
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-5">
      <v-card-title>
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="From Date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="fromMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="To Date"
              outlined
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="to" @input="toMenu = false"></v-date-picker>
        </v-menu>
      </v-card-title>

      <v-card-title>
        <v-icon>mdi-text-search</v-icon>
        <div class="text-uppercase">Search Results</div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          label="Search"
          dense
          v-model="search"
        ></v-text-field>
      </v-card-title>

      <v-card color="transparent">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              class="text-capitalize elevation-10"
              no-data-text="No results for the current date black--text"
              @click:row="pdfDialog = true"
            >
            </v-data-table>

            <v-dialog v-model="pdfDialog">
              <pdf-viewer></pdf-viewer>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import PdfViewer from "../PdfViewer.vue";
export default {
  components: { PdfViewer },
  data() {
    return {
      toMenu: false,
      fromMenu: false,
      pdfDialog: false,
      from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      headers: [
        {
          text: "meeting date & time",
          value: "date",
          class: "text-uppercase black--text",
        },
        {
          text: "title",
          value: "title",
          class: "text-uppercase black--text",
        },
      ],
      items: [
        {
          date: "Dec 10, 2021 7:30 AM",
          title: "Report fron POGO commitee",
        },
        {
          date: "Dec 12, 2021 4:30 AM",
          title: "Report fron POGO commitee",
        },
        {
          date: "Dec 13, 2021 10:30 AM",
          title: "Report fron POGO commitee",
        },
        {
          date: "Dec 14, 2021 9:00 AM",
          title: "Report fron POGO commitee",
        },
        {
          date: "Dec 16, 2021 11:30 AM",
          title: "Report fron POGO commitee",
        },
        {
          date: "Dec 18, 2021 9:30 AM",
          title: "Report fron POGO commitee",
        },
        {
          date: "Dec 20, 2021 10:30 AM",
          title: "Report fron POGO commitee",
        },
      ],
    };
  },
};
</script>

<style></style>
